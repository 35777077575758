@import "../../styles/core/spacing.scss";
@import "../../styles/core/breakpoints.scss";

.banner {
  position: fixed;
  top: $spacing-sm;
  right: $spacing-sm;
  max-width: 200px;
  border-radius: 6px;
  background-color: #000000;
  z-index: 10;
}
