@import "../../styles/core/breakpoints.scss";
@import "../../styles/core/spacing.scss";
@import "../../styles/core/color.scss";

.nav {
  width: 100vw;
  height: $spacing-lg;
  border-bottom: solid 1px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  padding: $spacing-sm;
  background-color: $color-greyDark;
  z-index: 2;
  position: sticky;
  top: 0;
  left: 0;
  @include mq("sm") {
    position: fixed;
    width: $spacing-lg;
    min-width: $spacing-lg;
    height: 100vh;
    display: block;
    border-bottom: none;
    border-right: solid 1px rgba(0, 0, 0, 0.5);
  }
}
.nav-logo {
  width: 40px;
  height: 40px;
  @include mq("sm") {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}
.nav-links {
  justify-content: space-between;
  align-items: center;
  display: flex;

  @include mq("sm") {
    position: absolute;
    bottom: 33px;
    text-align: center;
    display: block;
  }
}

.nav-link {
  margin: 0px $spacing-md;
  display: block;
  @include mq("sm") {
    line-height: 24px;
    transform: rotate(270deg);
    transform-origin: center center;
    margin: $spacing-md 0px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 1px;
      background-color: $color-white;
      transition: width 0.3s ease-out;
    }
    &:hover {
      &:before {
        width: 100%;
      }
    }
  }
  &.current {
    &:before {
      width: 100%;
    }
  }
}
