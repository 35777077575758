@import "../../styles/core/breakpoints.scss";
.filters {
  border: solid 1px rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  text-transform: uppercase;
  max-width: 500px;
}
.filter-btn {
  border-left: solid 1px rgba(255, 255, 255, 0.2);
  flex-grow: 1;
  @include mq("sm") {
    flex-direction: row-reverse;
  }
}
@include mq("sm") {
  .index-container {
    position: relative;
    &:before,
    &:after {
      content: "";
      height: 100%;
      width: 1px;
      background-color: rgba(255, 255, 255, 0.2);
      position: absolute;
      top: 0;
    }
    &:before {
      left: 30%;
    }
    &:after {
      left: 63%;
    }
  }
}
