@import "../../styles/core/color.scss";
@import "../../styles/core/breakpoints.scss";
@import "../../styles/core/spacing.scss";
.modale-full {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 200;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-out;
}
.modale-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0;
}
.modale-container {
  position: relative;
  z-index: 1;
  flex-basis: 90%;
  max-width: 90%;
  min-width: 90%;
  @include mq("sm") {
    flex-basis: 500px;
    max-width: 500px;
    min-width: 500px;
  }
  border-radius: 6px;
  background-color: $color-greyDark;
}

.modale-color-preview {
  height: $spacing-lg;
  flex-grow: 1;
}

.modale-open {
  opacity: 1;
  pointer-events: initial;
}
.selected-title {
  padding-right: $spacing-md;
  margin-right: $spacing-md;
  border-right: solid 1px rgba(255, 255, 255, 0.5);
}
.selected-clear {
  margin-bottom: 5px;
}
.selected-form {
  .status-success &,
  .status-loggedIn & {
    display: none;
  }
}
.selected-form-field {
  flex-grow: 1;
  margin-right: $spacing-md;
  background-color: transparent;
  color: $color-white;
  border: none;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
  &:focus {
    outline: none;
  }
}
