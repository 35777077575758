@import "../../styles/core/color.scss";
@import "../../styles/core/spacing.scss";

.border-bottom {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: currentColor;
    opacity: 0.3;
    display: block;
    width: 100%;
    height: 1px;
  }
}
.input-hidden {
  display: none;
}
.selected-info {
  &.dark {
    color: $color-greyDark;
    path {
      fill: $color-greyDark;
    }
  }
}

.copied {
  position: absolute;
  right: $spacing-sm;
  top: calc(50% - 10px);
}

.relative {
  position: relative;
}
