$breakpoint-xs: 400px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1440px;
$breakpoint-xxl: 1680px;
$breakpoint-hd: 1920px;

$breakpoints: (
  "xs": $breakpoint-xs,
  "sm": $breakpoint-sm,
  "md": $breakpoint-md,
  "lg": $breakpoint-lg,
  "xl": $breakpoint-xl,
  "xxl": $breakpoint-xxl,
  "hd": $breakpoint-hd,
);

@function mq-bp-width($name) {
  @if map-has-key($breakpoints, $name) {
    @return map-get($breakpoints, $name);
  } @else {
    @return $name;
  }
}

@mixin mq($bp, $until: false) {
  $breakpoint: mq-bp-width($bp);
  @if ($until == true) {
    @media (max-width: $breakpoint) {
      @content;
    }
  } @else {
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

@function mq-bp-width($name) {
  @if map-has-key($breakpoints, $name) {
    @return map-get($breakpoints, $name);
  } @else {
    @return $name;
  }
}

@mixin mq($bp, $until: false) {
  $breakpoint: mq-bp-width($bp);
  @if ($until == true) {
    @media (max-width: $breakpoint) {
      @content;
    }
  } @else {
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}
