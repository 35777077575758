@import "../../styles/core/spacing.scss";
@import "../../styles/core/breakpoints.scss";

.color-preview {
  font-weight: 500;
  width: 100%;
  flex-basis: 100%;
  @include mq("sm") {
    padding-right: 30px;
    flex-basis: 33.33%;
    min-width: 33.33%;
  }
}
.color-preview-box {
  flex-basis: 41px;
  width: 41px;
  min-width: 41px;
  height: 33px;
  @include mq("sm") {
    border: solid 1px rgba(245, 245, 233, 0.3);
  }
  border-radius: 1px;
}
