$fontPath: "/fonts/";

@font-face {
  font-family: "TitlingGothicFBWide-Thin";
  src: url($fontPath + "TitlingGothicFBWide-Thin.eot?#iefix")
      format("embedded-opentype"),
    url($fontPath + "TitlingGothicFBWide-Thin.otf") format("opentype"),
    url($fontPath + "TitlingGothicFBWide-Thin.woff") format("woff"),
    url($fontPath + "TitlingGothicFBWide-Thin.ttf") format("truetype"),
    url($fontPath + "TitlingGothicFBWide-Thin.svg#TitlingGothicFBWide-Thin")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TitlingGothicFBWide-Regular";
  src: url($fontPath + "TitlingGothicFBWide-Regular.eot?#iefix")
      format("embedded-opentype"),
    url($fontPath + "TitlingGothicFBWide-Regular.otf") format("opentype"),
    url($fontPath + "TitlingGothicFBWide-Regular.woff") format("woff"),
    url($fontPath + "TitlingGothicFBWide-Regular.ttf") format("truetype"),
    url($fontPath + "TitlingGothicFBWide-Regular.svg#TitlingGothicFBWide-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
