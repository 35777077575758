@import "../../styles/core/breakpoints.scss";
@import "../../styles/core/spacing.scss";
.intro {
  position: relative;
  min-width: unset;
  max-width: unset;

  @include mq("sm") {
    width: $main-wrapper;
    min-width: $main-wrapper;
    max-width: $main-wrapper;
    padding-top: 0px;
  }
}

.back-link {
  position: absolute;
  top: 33px;
  right: 33px;
  width: 14px;
  height: 14px;
}
