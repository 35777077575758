body {
  background: $color-greyDark;
  color: $color-white;

  .icon {
    path {
      fill: $color-white;
    }
  }
}
.u-color-beige {
  color: $color-beige;
  .icon {
    path {
      fill: $color-beige;
    }
  }
}
.u-color-black {
  color: $color-black;
  .icon {
    path {
      fill: $color-black;
    }
  }
}
.icon {
  cursor: pointer;
  path {
    fill: $color-black;
  }
}
