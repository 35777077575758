[class*="flex"] {
  display: flex;
  flex-wrap: nowrap;
}
[class*="childGrow"] {
  flex-grow: 1;
}
[class*="-column"] {
  flex-direction: column;
}
[class*="-flexWrap"] {
  flex-wrap: wrap;
}
[class*="-fullWidth"] {
  width: 100%;
  flex-basis: 100%;
}
[class*="-fullHeight"] {
  height: 100%;
  flex-basis: 100%;
}

[class*="-spaceBetween"] {
  justify-content: space-between;
}
[class*="-reverse"] {
  flex-direction: row-reverse;
}
[class*="-spaceAround"] {
  justify-content: space-around;
}
[class*="-justifyCenter"] {
  justify-content: center;
}
[class*="-alignCenter"] {
  align-items: center;
}
[class*="-alignTop"] {
  align-items: flex-start;
}
[class*="-alignBottom"] {
  align-items: flex-end;
}
[class*="-grow"] {
  flex-grow: 1;
}

.align-self-btm {
  align-self: flex-end;
}
.align-self-ctr {
  align-self: center;
}
