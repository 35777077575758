@import "../../styles/core/breakpoints.scss";
@import "../../styles/core/color.scss";
@import "../../styles/core/spacing.scss";

.panel {
  background-color: $color-greyDark;
  pointer-events: none;
  z-index: 2;
  overflow: scroll;
  position: fixed;
  .panel-content {
    transition: opacity 0.5s ease-out;
    opacity: 0;
  }
  top: $spacing-lg;
  left: 0;
  height: 0px;
  @include mq("sm") {
    height: 100vh;
    width: 0px;
    top: 0;
    left: $spacing-lg;
    transition: width 0.5s ease-out 0.5s;
  }
}
.panel-opened {
  pointer-events: auto;
  opacity: 1;
  @include mq("sm", true) {
    height: calc(100vh - #{$spacing-lg});
  }

  overflow: scroll;
  cursor: default;
  @include mq("sm") {
    transition: width 0.5s ease-out;
    width: $main-wrapper;
  }
  .panel-content {
    opacity: 1;
    transition: opacity 0.5s ease-out 0.5s;
  }
}
.panel-large {
  .panel-content {
    height: 100vh;
    padding: 0;
  }

  iframe {
    height: 100%;
    body {
      background-color: unset !important;
    }
  }
  &.panel-opened {
    width: 100vw;
    @include mq("sm") {
      width: calc(100vw - 80px);
    }
  }
}

button.panel-close {
  position: sticky;
  top: $spacing-md;
  left: calc(100% - #{$spacing-md});
  z-index: 2;
}
