@import "../../styles/core/spacing.scss";
@import "../../styles/core/breakpoints.scss";

.color-title {
  @include mq("sm") {
    width: $spacing-lg !important;
    height: 100vw;
  }
}
.color-title-sticky-wrapper {
  height: 100%;
  @include mq("sm") {
    width: $spacing-lg !important;
    height: 0px;
    position: sticky;
    top: 0;
    left: calc(100% - #{$spacing-lg});
  }
}

.color-twisted {
  @include mq("sm") {
    position: absolute;
    top: calc(100vh - #{$spacing-lg});
    left: calc(100% - 33px);
    transform: rotate(-90deg);
    transform-origin: left bottom;
    min-width: 250px;
  }
}
.color-icon {
  @include mq("sm", true) {
    padding-right: $spacing-sm;
  }
  @include mq("sm") {
    position: absolute;
    display: inline-block;
    top: calc(100vh - #{$spacing-md * 2});
    left: calc(100% - 50px);
  }
}
.color {
  @include mq("sm") {
    height: 100vh;
    min-width: $spacing-lg;
    transition: min-width 0.5s ease-out 0.5s;
  }
  @include mq("sm", true) {
    height: $spacing-lg;
    max-height: $spacing-lg;
  }
  transition: max-height 0.5s ease-out 0.5s;

  overflow: hidden;

  .color-detail {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-out;
    overflow: scroll;

    @include mq("sm") {
      width: $main-wrapper;
    }
  }
}
.color-opened {
  height: auto;
  overflow: scroll;
  position: relative;
  cursor: default;
  @include mq("sm") {
    transition: min-width 0.5s ease-out;
    min-width: $main-wrapper;
  }
  @include mq("sm", true) {
    transition: height 0.5s ease-out;
    height: auto;
    max-height: unset;
  }

  .icon-close {
    position: absolute;
    top: $spacing-md;
    right: $spacing-md;
  }
  .color-detail {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.5s ease-out 0.5s;
  }
}
.color-image-text {
  @include mq("sm", true) {
    flex-wrap: wrap !important;
  }
}
.color-text {
  @include mq("sm", true) {
    padding-top: $spacing-sm !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.color-image-small {
  pointer-events: none;
  display: block;
  max-width: 100%;
  width: 100%;
  @include mq("sm") {
    max-width: 370px;
    width: 370px;
  }
}
.color-image-medium {
  pointer-events: none;
  display: block;
  max-width: 100%;
  width: 100%;
  @include mq("sm") {
    max-width: 504px;
    width: 504px;
  }
}
.color-image-large {
  pointer-events: none;
  display: block;
  max-width: 100%;
  width: 100%;
}
.color-text-right {
  text-align: right;
}
