@import "./breakpoints.scss";
@mixin spacings($size, $spacing) {
  .u-my#{$size} {
    margin-top: $spacing;
    margin-bottom: $spacing;
  }
  .u-mx#{$size} {
    margin-left: $spacing;
    margin-right: $spacing;
  }
  .u-m#{$size} {
    margin: $spacing;
  }
  .u-mt#{$size} {
    margin-top: $spacing;
  }
  .u-mb#{$size} {
    margin-bottom: $spacing;
  }
  .u-ml#{$size} {
    margin-left: $spacing;
  }
  .u-mr#{$size} {
    margin-right: $spacing;
  }
  .u-py#{$size} {
    padding-top: $spacing;
    padding-bottom: $spacing;
  }
  .u-px#{$size} {
    padding-left: $spacing;
    padding-right: $spacing;
  }
  .u-p#{$size} {
    padding: $spacing;
  }
  .u-pt#{$size} {
    padding-top: $spacing;
  }
  .u-pb#{$size} {
    padding-bottom: $spacing;
  }
  .u-pl#{$size} {
    padding-left: $spacing;
  }
  .u-pr#{$size} {
    padding-right: $spacing;
  }
}

@include spacings("-null", "0px");
@include spacings("-xs", $spacing-xs);
@include spacings("-sm", $spacing-sm);
@include spacings("-md", $spacing-md);
@include spacings("-lg", $spacing-lg);
@include mq("lg") {
  @include spacings("_lg-null", "0px");
  @include spacings("_lg-xs", $spacing-xs);
  @include spacings("_lg-sm", $spacing-sm);
  @include spacings("_lg-md", $spacing-md);
  @include spacings("_lg-lg", $spacing-lg);
}
@include mq("md") {
  @include spacings("_md-null", "0px");
  @include spacings("_md-xs", $spacing-xs);
  @include spacings("_md-sm", $spacing-sm);
  @include spacings("_md-md", $spacing-md);
  @include spacings("_md-lg", $spacing-lg);
}

@include mq("sm") {
  @include spacings("_sm-null", "0px");
  @include spacings("_sm-xs", $spacing-xs);
  @include spacings("_sm-sm", $spacing-sm);
  @include spacings("_sm-md", $spacing-md);
  @include spacings("_sm-lg", $spacing-lg);
}
