@import "../../styles/core/spacing.scss";

.horizontal-wrapper {
  display: flex;
  height: 100vh;
  width: calc(100vw - #{$spacing-lg});
  margin-left: $spacing-lg !important;

  .horizontal-child {
    height: 100vh;
    position: relative;
  }
  .horizontal-child-scrollable {
    height: 100vh;
    overflow: scroll;
  }
  .horizontal-child.expand {
    width: $main-wrapper;
  }

  .swiper-wrapper {
    width: 100%;
  }

  .swiper-slide {
    .color {
      max-width: $spacing-lg;
    }
    width: auto;
    flex-shrink: 1;
  }
}
