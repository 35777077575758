@import "../../styles/core/breakpoints.scss";

body {
  //TitlingGothicFBWide-Thin
  font-family: "Titling Gothic FB Nrm Reg";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
}

h1,
.h1 {
  font-family: "Titling Gothic FB Wd Med";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 46px;
  letter-spacing: -0.05em;
  color: $color-beige;
  @include mq("sm") {
    font-size: 68px;
    line-height: 66px;
  }
}
//TitlingGothicFBWide-Thin

h2,
.h2 {
  font-family: "Titling Gothic FB Wd Med";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.05em;
}

h3,
.h3 {
  font-family: "Titling Gothic FB Wd Med";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
}

h4,
.h4 {
  font-family: "Titling Gothic FB Wd Med";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
}

h5,
.h5 {
  font-family: "Titling Gothic FB Wd Med";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.body2 {
  font-family: "Titling Gothic FB Nrm Reg";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.captionTitle {
  font-family: "Titling Gothic FB Nrm Med";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  @include mq("sm") {
    font-size: 12px;
    line-height: 24px;
  }
}
.captionBody {
  font-family: "Titling Gothic FB Nrm Lt";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  @include mq("sm") {
    font-size: 12px;
    line-height: 24px;
  }
}

.captionDescription {
  font-family: "Titling Gothic FB Nrm Lt";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  @include mq("sm") {
    font-size: 10px;
    line-height: 12px;
  }
}
.colorDescription {
  font-family: "Titling Gothic FB Nrm Reg";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.5px;
  @include mq("sm") {
    font-size: 14px;
    line-height: 20px;
  }
}
.nav {
  font-family: "Titling Gothic FB Nrm Lt";
  font-style: normal;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: lowercase;
}
.bodyFilter {
  font-family: "Titling Gothic FB Nrm Reg";
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.icon {
  width: 24px;
  height: 24px;
  position: relative;
  svg {
    width: 100%;
    height: 100%;
  }
}

.icon-xs {
  width: 10px;
  height: 10px;
}

.icon-sm {
  width: 14px;
  height: 14px;
}
.icon-number {
  width: 20px;
  height: 20px;
  padding: 5px;
  background: #bb6bd9;
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 100%;
}

.typo-uppercase {
  text-transform: uppercase;
}

.no-copy {
  pointer-events: none;
  user-select: none;
}

.button {
  background-color: $color-white;
  color: $color-greyDark;
  padding: 8px 20px;
  border-radius: 6px;
  text-align: center;
}
.button-full {
  width: 100%;
  text-align: center;
}

.typo-underline {
  text-decoration: underline;
  cursor: pointer;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.8;
  }
}
